<template>
  <div v-if="isNotificationPermissionUnset"
       class="comNotificationsDeniedComponent">
    <ion-toast
      :buttons="buttons"
      :header="$t('Notifications are not enabled!')"
      :isOpen="true"
      :message="$t('Please allow browser notifications to enable real-time notifications')"
      layout="stacked" />
  </div>
</template>

<script lang="ts" setup>
import {IonToast} from "@ionic/vue";
import i18n from "@/i18n";
import useBrowserNotification from "@/composables/messaging/useBrowserNotification";

const {isNotificationPermissionUnset, requestNotificationPermission} = useBrowserNotification();

const buttons = [
  {
    text: i18n.global.t('Click here to enable'),
    side: 'end',
    handler: () => {
      requestNotificationPermission()
    }
  }
]

</script>
