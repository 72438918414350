import {computed} from "vue";

export default function useBrowserNotification() {

    const isNotificationPermissionUnset = computed(() => {
        return Notification.permission === "default";
    });

    const isNotificationPermissionDenied = computed(() => {
        return Notification.permission === "denied";
    });

    const notificationsAreDisabled = computed(() => {
        return Notification.permission !== "granted";
    });

    // eslint-disable-next-line @typescript-eslint/ban-types
    function requestNotificationPermission(callback?: Function) {
        Notification.requestPermission()
            .then((r) => {
                if (typeof callback === "function") {
                    callback();
                } else {
                    if (r === "granted") {
                        window.location.reload();
                    }
                }
            })
    }

    return {
        isNotificationPermissionUnset,
        isNotificationPermissionDenied,
        notificationsAreDisabled,
        requestNotificationPermission
    }

}