export default function useParsers() {

    /**
     * Cast parameter as boolean
     * @param value - any value with primitive type
     */
    function parseBool(value: number | string | undefined | null | boolean): boolean {
        switch (typeof value) {
            case 'number':
                return value === 1
            case 'string':
                return value === '1' || value === 'true'
            case 'boolean':
                return value
            case 'undefined':
                return false
        }
        return false;
    }

    /**
     * Check if object is empty
     * @param value
     */
    function isEmpty(value: number | string | undefined | null | boolean | any[] | object): boolean {
        if (Array.isArray(value)) {
            return value.length > 0
        }
        if (typeof value === 'undefined' || value === null) {
            return true
        }
        switch (typeof value) {
            case 'string':
                return value.length === 0
            case 'number':
                return value === 0;
            case 'boolean':
                return !value;
            case 'object':
                return Object.keys(value).length === 0;
        }
        return true;
    }

    function parseNumberByCurrency(
        currency: string|null|undefined,
        value: number | string | undefined | null,
        defaultValue: undefined | string | number = undefined,
        digits = 2): any {

        if (typeof value === 'string') {
            value = parseFloat(value)
        }

        const lc = (currency || '').toLowerCase()

        if (value === null || typeof value === 'undefined' || value === 0 || value === 0.00) {
            return defaultValue ?? value
        } else {
            return value.toFixed(['huf', 'ft'].includes(lc) ? 0 : digits)
        }
    }

    return {
        parseBool,
        isEmpty,
        parseNumberByCurrency
    }
}
