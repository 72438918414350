export default function useLocalStorage() {

  function parseUser() {
    return JSON.parse(localStorage.getItem('service_app_user'))
  }

  return {
    parseUser,
  }
}
