import { createStore } from 'vuex'
import app from './modules/app';
import auth from './modules/auth';
import load from './modules/load';
import user from './modules/user';
import error from './modules/error';
import offlineWorksheet from './modules/offlineWorksheet';
import offlineParts from './modules/offlineParts';
import measurementGroups from './modules/measurementGroups';
import notificationsStore from "@/store/modules/notificationsStore";

export default createStore({

  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    offlineParts: offlineParts,
    offlineWorksheet: offlineWorksheet,
    app: app,
    user: user,
    auth: auth,
    load: load,
    error: error,
    measurementGroups: measurementGroups,
    notifications: notificationsStore,
  }
})
