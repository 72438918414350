import {toastController} from "@ionic/vue";
import {checkmarkCircle} from "ionicons/icons";
export default function useToast() {

    async function showToast(message: string, position: "top" | "bottom" | "middle" = "bottom") {
        const toast = await toastController.create({
            message: message,
            duration: 3000,
            position: position
        });

        await toast.present();
    }

    async function showPersistentToast(message: string, position: "top" | "bottom" | "middle" = "top") {

        const toastButtons = [
            {
                text: 'Dismiss',
                role: 'cancel',
            },
        ];

        const toast = await toastController.create({
            message: message,
            position: position,
            buttons: toastButtons
        });


        await toast.present();
    }

    async function showInfoToast(message: string, position: "top" | "bottom" | "middle" = "top") {
        const toast = await toastController.create({
            message: message,
            duration: 5000,
            position: position,
            icon: checkmarkCircle,
            color: "success"
        });

        await toast.present();
    }


    return {
        showToast,
        showInfoToast,
        showPersistentToast
    }

}