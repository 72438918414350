import {useStore} from "vuex";
import {computed, nextTick, ref, type Ref} from "vue";
import Echo from "laravel-echo";
import useParsers from "@/composables/useParsers";
import axios from 'axios';
import {configWithAuth} from "@/store/api_configs.js";
import DefaultWebsocketMessage from "@/composables/messaging/DefaultWebsocketMessage";
import {IReverbNotification} from "@/composables/messaging/IReverbNotification";
import useToast from "@/composables/notifications/useToast";

export default function useReverb() {
    const {parseBool} = useParsers()
    const store = useStore();
    const {showPersistentToast} = useToast();

    // eslint-disable-next-line @typescript-eslint/ban-types
    const onConnectedCallback: Ref<Function | undefined> = ref(undefined)

    const user = computed(() => store.getters["user/getUser"])

    const channelName = computed(() => `appNotifications.${user.value.uuid}`)

    const reverbConfiguration = {
        broadcaster: "reverb",
        key: process.env.VUE_APP_WS_APP_KEY,
        cluster: process.env.VUE_APP_WS_CLUSTER,
        forceTLS: parseBool(process.env.VUE_APP_WS_FORCE_TLS),
        encrypted: true,
        wsHost: process.env.VUE_APP_ECHO_HOST,
        wsPort: process.env.VUE_APP_ECHO_PORT,
        activityTimeout: 120000,
        pongTimeout: 5000, // 30000,
        unavailableTimeout: 5000, // 10000,
        authEndpoint: process.env.VUE_APP_ECHO_AUTH_ENDPOINT,
        enabledTransports: ["ws", "wss"],
        auth: {
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "XMLHttpRequest",
                "X-User-UUID": user.value.uuid,
                Authorization: "Bearer " + (user.value.token || ""),
            },
        },
    };

    const reverb: Ref<Echo | undefined> = ref();

    function initializeReverb(): void {
        console.info("Reverb -> initialize")
        reverb.value = new Echo(reverbConfiguration)

        nextTick(() => {
            if (reverb.value) {
                reverb.value.connector.pusher.connection.bind("connecting", () => {
                    console.log("Reverb -> connecting...");
                    return;
                });
                reverb.value.connector.pusher.connection.bind("connected", () => {
                    console.log("Reverb -> Connected to server");
                    if (typeof onConnectedCallback.value !== "undefined") {
                        onConnectedCallback.value()
                    }
                });
                reverb.value.connector.pusher.connection.bind("unavailable", (e) => {
                    console.warn(`Reverb -> warn: unavailable`);
                });
                reverb.value.connector.pusher.connection.bind("failed", (e) => {
                    console.error(`Reverb -> err: failed`);
                });
                reverb.value.connector.pusher.connection.bind("disconnected", (e) => {
                    console.info(`Reverb -> info: disconnected`);
                });
                subscribeToEvents();
            }
        })
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    function setOnConnectedCallback(callback: Function): void {
        onConnectedCallback.value = callback
    }

    function sendMessage(message: DefaultWebsocketMessage): void {
        axios(configWithAuth("post", "internal-messaging/createWebsocketMessage", message))
            .then(() => {
                console.log("Reverb API -> message send request complete")
            })
            .catch(e => console.log(`Reverb -> sendMessage -> error: ${e.message}`))
            .then(() => {
                console.log("Reverb API -> sendMessage -> finish")
            })
    }

    async function subscribeToEvents(): Promise<void> {
        if (reverb.value) {
            reverb.value.channel(channelName.value)
                .listen(".AppNotificationCreated", (notification: IReverbNotification) => {
                    console.log(notification)
                    switch (notification.message_type) {
                        case "worksheetServiceableNotification":
                            showPersistentToast(notification.message);
                            store.commit("notifications/addUnreadNotification", notification)
                            break;
                    }
                });
        }
    }

    return {
        reverbInstance: reverb,
        initializeReverb,
        setOnConnectedCallback,
        sendMessage,
    }
}