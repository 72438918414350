import {IReverbNotification} from "@/composables/messaging/IReverbNotification";
export default {
    namespaced: true,
    state: {
        unreadNotifications: JSON.parse(localStorage.getItem("websocketNotifications") || '[]')
    },
    actions: {

    },
    mutations: {
        addUnreadNotification(state, notification: IReverbNotification) {
            state.unreadNotifications.push(notification);
            localStorage.setItem("websocketNotifications", JSON.stringify(state.unreadNotifications));
        },
        setUnreadNotifications(state, notifications: IReverbNotification[]) {
            state.unreadNotifications = notifications;
        },
        remove(state, notification: IReverbNotification) {
            state.unreadNotifications.splice(state.unreadNotifications.indexOf(notification), 1)
            localStorage.setItem("websocketNotifications", JSON.stringify(state.unreadNotifications));
        }
    },
    getters: {
        unreadNotifications(state) {
            return state.unreadNotifications
        }
    }
}
